import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Stack,
  Modal
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { fetchAllJobRequests } from '../../api';
import { fetchOneJobRequest } from '../../api/jobRequest';
import SingleJobDetail from './SingleJobDetail';
import useMediaQuery from '@mui/material/useMediaQuery';

function Index () {
  const [view, setView] = useState('Current Request');
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [dataCount, setDataCount] = useState();

  const [openJobDetails, setOpenJobDetails] = useState(false);
  const [checkJob, setCheckJob] = useState([]);

  const handleCloseJobDetails = () => setOpenJobDetails(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left'
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2
  };

  const columns = [
    { field: 'title', headerName: 'Job Title', width: 160 },
    {
      field: 'status',
      headerName: 'Status',
      width: 120
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 200
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300
    },
    {
      field: 'date',
      headerName: 'Date Created',
      description: 'date',
      width: 160
    }
  ];

  const handleChange = (event, newAuthForm) => {
    if (newAuthForm !== null) {
      setView(newAuthForm);
    }
  };
  let rows;

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllJobRequests({ page });
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      rows = data?.data.map((requestData) => ({
        ...requestData,
        id: requestData?.id,
        location: requestData?.location?.name,
        title: requestData?.request_type?.name,
        date: moment(requestData?.created_at).format('ll')
      }));
      setRowData(rows);
      setDataCount(data.count);
    }
  };

  const getOneRequest = async (id) => {
    setLoading(true);
    const response = await fetchOneJobRequest(id);
    setLoading(false);

    if (response?.success && response?.data) {
      setCheckJob(response?.data);
    }
  };

  const handleOpenJobDetails = (id) => {
    getOneRequest(id);
    setOpenJobDetails(true);
  };

  useEffect(() => {
    getJobRequests(1);
  }, []);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      style={{
        minHeight: '100vh',
        width: '100%',
        alignItems: 'center',
        padding: { lg: 5, sm: 3 }
      }}
    >
      <Grid container sx={{ margin: { lg: 'auto' } }} p={5}>
        <Grid
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          container
        >
          <Grid item>
            <Typography variant="h5" component="h3" fontWeight={'bold'}>
              Dashboard
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={4} columnSpacing={2}>
          <Grid xs={12} mb={2} lg={4} item>
            <Paper
              sx={{
                width: { lg: 300, md: 300 },
                height: 180,
                backgroundColor: '#FAFAFA',
                border: '1px solid #EFEFEF',
                borderRadius: 2
              }}
            >
              <Stack p={5}>
                <Typography
                  variant="h5"
                  component="h4"
                  fontWeight="bold"
                  mt={3}
                >
                  Pending Request
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  component="span"
                  color="primary"
                  align="center"
                  p={1}
                >
                  {dataCount?.pending}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid xs={12} mb={2} lg={4} item>
            <Paper
              sx={{
                width: { lg: 300, md: 300 },
                height: 180,
                backgroundColor: '#FAFAFA',
                border: '1px solid #EFEFEF',
                borderRadius: 2
              }}
            >
              <Stack p={5}>
                <Typography
                  variant="h5"
                  component="h4"
                  fontWeight="bold"
                  mt={3}
                >
                  Current Request
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  component="span"
                  color="primary"
                  align="center"
                  p={1}
                >
                  {dataCount?.accepted}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid xs={12} mb={2} lg={4} item>
            <Paper
              sx={{
                width: { lg: 300, md: 300 },
                height: 180,
                backgroundColor: '#FAFAFA',
                border: '1px solid #EFEFEF',
                borderRadius: 2
              }}
            >
              <Stack p={5}>
                <Typography
                  variant="h5"
                  component="h4"
                  fontWeight="bold"
                  mt={3}
                >
                  Completed Request
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  component="span"
                  color="primary"
                  align="center"
                  p={1}
                >
                  {dataCount?.completed}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          // display: 'flex',
          // alignItems: 'center',
          pl: 5,
          pb: 1,
          maxWidth: 1000
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={view}
          onChange={handleChange}
          exclusive
          p={10}
        >
          <ToggleButton sx={{ border: 'none' }} value="Current Request">
            Recent Requests
          </ToggleButton>
        </ToggleButtonGroup>
        <Paper elevation={0} justify="center">
          <LinearProgress variant="determinate" value={100} />
          {view === 'Current Request' && <Grid item sm={12}></Grid>}
          <Box width="100%">
            <DataGrid
              // rowCount={rowCount}
              rows={rowData}
              columns={columns}
              loading={loading}
              onRowClick={(row) => handleOpenJobDetails(row.id)}
              // pageSize={pageSize}
              // onPageChange={getPage}
              // page={page}
              autoHeight
              rowsPerPageOptions={[15]}
              paginationMode="server"
            />
          </Box>
        </Paper>
        <Modal
          open={openJobDetails}
          onClose={handleCloseJobDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <SingleJobDetail job={checkJob} />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Index;
