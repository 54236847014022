import {
  Box,
  List,
  ListItem,
  Link as MUILink,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  AccountCircle,
  Logout,
  LocalActivityOutlined,
  DashboardCustomizeOutlined,
  RequestQuoteOutlined,
  Assessment,
  SettingsApplicationsOutlined,
  Receipt,
  SettingsAccessibilityOutlined
} from '@mui/icons-material';

import { Logo } from '../';

export default function Sidebar () {
  const navLinkStyle = ({ isActive }) => ({
    color: isActive ? 'primary' : 'inherit',
    fontWeight: isActive ? 'bold' : 'normal'
  });

  const logout = () => {
    window.localStorage.clear();
    window.location.reload('/');
  };

  const userType = localStorage.getItem('user-role');
  console.log(userType)
  return (
    <>
      {userType === '"assessor"' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'fixed',
            minHeight: '100vh',
            top: 0,
            left: 0,
            minWidth: { md: '25vw', lg: '20vw', xl: '15vw' },
            width: '250px',
            background: '#FAFAFA',
            border: '1px solid  #FAFAFA',
          }}
        >
          <List sx={{ width: '100%' }}>
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Logo />
              </Box>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <Assessment />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="assessments"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Assessments" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem py={1}>
              <ListItemButton>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to=""
                  onClick={() => {
                    logout();
                  }}
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Logout" />
                </MUILink>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      )}

      {(userType === '"super-admin"' || userType === '"admin"') && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'fixed',
            minHeight: '100vh',
            top: 0,
            left: 0,
            bottom: 0,
            minWidth: { md: '25vw', lg: '20vw', xl: '15vw' },
            width: '250px',
            background: '#FAFAFA',
            border: '1px solid  #FAFAFA',
            overflowY: 'scroll'
          }}
        >
          <List sx={{ width: '100%', minHeight: '100vh' }}>
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Logo />
              </Box>
            </ListItem>
            <ListItem
              sx={{
                '&:hover': {
                  color: 'primary',
                },
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DashboardCustomizeOutlined />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="/"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Dashboard" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <RequestQuoteOutlined />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="job-requests"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Requests" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="all-customers"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary=" Customers" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="artisans"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Artisans" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <LocalActivityOutlined />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="quotations"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Quotations" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="receipt"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Receipt" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <Assessment />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="assessments"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Assessments" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsApplicationsOutlined />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="app-management"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="App Management" />
                </MUILink>
              </ListItemButton>
            </ListItem>
            {userType === '"super-admin"' && (
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsAccessibilityOutlined />
                  </ListItemIcon>
                  <MUILink
                    underline="none"
                    component={NavLink}
                    to="roles-functions"
                    color="inherit"
                    style={navLinkStyle}
                  >
                    <ListItemText primary="Roles" />
                  </MUILink>
                </ListItemButton>
              </ListItem>
            )}
            <ListItem py={1}>
              <ListItemButton>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to=""
                  onClick={() => {
                    logout();
                  }}
                  color="inherit"
                  style={navLinkStyle}
                >
                  <ListItemText primary="Logout" />
                </MUILink>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      )}
    </>
  );
}
